@import '../variables.scss';
@import '../common.scss';

.info-card {
    background-color: $card;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 539px;
    height: 177px;
    padding: 21px 27px;
    .welcome-title {
      margin: 0;
      color: $primary;
    }
    .first-time-btn {
      @extend .btn-primary;
      width: 12em;
      
    }
}