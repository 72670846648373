@import './variables.scss';
@import './layouts.scss';

// Common Classes

.loadingContainer {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 17%;
}

.modal-overlay {
  display: block;
  background: rgba(0,0,0,.7);
  position: block;
  z-index: 100;
  .modal {
    top: 0;
    left: 50px;
    bottom: 0;
    height: 600px;
    width: 600px;
    background: white;
    display: block;
    z-index: 101;
  }
}

.pointer {
  cursor: pointer;
}


.width-100 {
  width: 100%;  
}

.date-picker {
  z-index: 102;
}

// Typography

.h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 54px;
  line-height: 63px;
  letter-spacing: 0.03em;
  color: $text;  
}

.h2 {
  font-style: normal;
  font-size: 32px;
  line-height: 63px;
  letter-spacing: 0.03em;
  color: $primary;  
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 63px;
  letter-spacing: 0.03em;
  color: #000000;  
}

.title2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: 0.03em;
  margin: .4em;
}

.body1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.03em;
  margin: .4em;
}

.button {
  font-size: 1em;
  font-weight: bolder;
}

.bold {
  font-weight: bolder;
}

.error-msg {
  color: $error;
  font-weight: bolder;
  font-size: 1em;
}

// Buttons

.btn {
  width: 185px;
  height: 38px;
  color: $offtext;
  font-size: 1.2em;
  font-weight: bold;
  border: none;
  transition: 0.4s;
}

.btn-primary {
  @extend .btn;
  background: $primary;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &:hover {
    background: $primarydark;
  }
  &:focus {
    background: $primary;
  }
}

.btn-danger {
  @extend .btn;
  background: $error;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &:hover {
    background: $errorHover;
  }
}

// Pushes

.pushLeftSm {
  margin-right: .2em;
}

// Transitions

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

// Icons

.icon {
  font-size: 1.8em;
  color: $primarydark;
}