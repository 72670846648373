.spread-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.center-row {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flex-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.center {
    text-align: center;
    margin: 0 auto;
}