@import '../styles/common.scss';
@import './/variables.scss';

.right-of-bar {
  margin-left: 10.2em;
}


.left-bar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 96px;
  background: $primarydark;
  text-align: center;
  color: white;
  z-index: 100;
  .top-section {
    height: 12em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-weight: bold;
    .logo {
      width: 4em;
    }
    .profile-photo {
      height: 70px;
      width: 70px;
      border-radius: 50%;
      border: solid 3px white;
      background-size: contain;
      cursor: pointer;
      transition: filter 250ms;

      &:hover {
        filter: brightness(110%);
      }

      &.none {
        background: url('http://www.sackettwaconia.com/wp-content/uploads/default-profile.png');
        background-size: contain;
      }
  
    }

  }

  .middle-section {
    border-top: solid 1px rgba(255,255,255,.2);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 5px;

    a {
      position: relative;
      width: 100%;
      color: white;
      text-align: left;
      padding: 1.4em 0px;
      font-weight: bold;
      transition: background 150ms;
      margin-bottom: 5px;
      text-align: center;
      i {
        text-align: center;
        left: 30px;
        font-size: 1.3rem;
      }
      &:hover {
        background: rgba(32, 109, 198, 0.4);
      }
      &.active {
        background: rgba(32, 109, 198, 0.4);
        border-left: solid 6px white;  
      }
    }
  }

  .bottom-section {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: solid 1px rgba(255,255,255,.2);

    a {
      width: 100%;
      color: white;
      font-weight: bold;
      transition: all 150ms;
    }
  }

  .modal-overlay {
    display: block;
    background: rgba(0,0,0,.7);
    position: absolute;
    z-index: 100000;
    width: 100vw;

    .modal {
      top: 0;
      left: 250px;
      bottom: 0;
      height: 600px;
      width: 600px;
      background: white;
      display: block;
      input {
        color: black;
      }

      button {
        color: black;
      }
    }
  }

}

.top-bar {
  position: absolute;
  background: white;
  top: 0;
  left: 96px;
  z-index: 1;
  width: calc(100% - 96px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -3px 5px rgba(0,0,0,.3);
  height: 60px;
  padding: 0em 3em;
  .move-top-bar-header {
    margin-left: 1rem;
  }

  .new-project-cta {
    position: absolute;
    top: 1rem;
    right: 20rem;
    height: 30px;
    width: 150px;
    background: #273c75;
    border: none;
    color: white;
    z-index: 10;
    font-weight: bold;
  }
}
