.error-page {
  height: 100vh;
  width: 100vw;
  display: inline-block;
  background: #00AFF9 url(https://cbwconline.com/IMG/Codepen/Unplugged.png) center/cover no-repeat;
  height: 100vh;
  width: 100vw;
  margin: 0;
  color: white;
  h1 {
    margin: .8em 3rem;
    font: 4em Roboto;
  }
  p {
    display: inline-block;
    margin: .2em 3rem;
    font: 2em Roboto;
  }
}