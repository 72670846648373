@import '../variables.scss';

.stats-cards {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 2em;
    margin-right: 4em;
    height: 20em;
}

.stat-card {
    background-color: $offtext;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    width: 330px;
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 2.2em;
    padding-right: 1.2em;

    .content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .emoji {
        text-align: right;
    }
    .aqua {
        color: $secondary;
    }
}