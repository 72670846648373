.setup {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    position: absolute;
    top: 0;
    color: #3498db;
  }
  h2 {
    padding-top: 3rem;
    font-weight: 300;
    color: rgb(41, 41, 41);
  }
  .setup-boxes {
    width: 80%;
    display: flex;
    justify-content: space-around;
  }

  .setup-box {
    height: 360px;
    width: 340px;
    background: white;
    border-radius: 6px;
    border: solid 1px rgba(0,0,0,.03);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
    transition: all 250ms;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    &.client-box-img {
      background-image: url(../../assets/setup/client.svg)
    }
    &.contractor-box-img {
      background-image: url(../../assets/setup/contractor.svg)
    }
    &:hover {
      transform: translateY(-5px);
    }

    h3 {
      text-align: center;
    }
  }
  // contractor step 1
  .list-types {
    height: 400px;
    text-align: center;
    li {
      background-color: white;
      padding: 1rem;
      margin: 1rem;
      display: block;
      width: 700px;
      font-size: 2rem;
      font-weight: 300;
      cursor: pointer;
      transition: all 250ms;
      
      &:hover {
        transform: translateY(-3px);
        box-shadow: 0px 5px 8px 0px #3498db40;
      }
    }
  }

  .form {
    padding-top: 2rem;
    .contractorId-input {
      width: 30rem;
      font-size: 1.4rem;
    }
  }

  .last-step-btn, %last-step-btn {
    width: 454px;
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: 300;
    background: #2980b9;
    color: white;
  }


  .final-btn {
    @extend %last-step-btn;
    background-color: #2ecc71;
  }

  .card-img {
    max-width: 23rem;
  }

}

.invite-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  .contractor-invite-photo {
    height: 60px;
    border-radius: 50%;
    border: solid 2px black;
    margin: 2rem;
  }

  h1 {
    color: #2c3e50;
  }

  a {
    background: #2980b9;
    color: white;
    width: 500px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
  }
}

