.clients {
  padding: 1rem;
  height: 100vh;
  overflow-y: scroll;
  padding-top: 3rem;

  h2 {
    font-size: 20px;
  }

  .copy-input-imp {
    border: #d5d8d7 solid 1px;
    background: white;
    padding-left: 1rem !important;
    width: 30rem !important;
    height: 3rem;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right: none !important;
  }
  
  .copy-button-imp {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-left: none;
    height: 3.2rem;
    transform: translateY(-1px);
    background: #ecf0f1;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }



}

.delete-client {
  position: absolute;
  top: 0;
  right: -50px;
  z-index: 100;
  color: black;
  height: 58px;
  width: 50px;
  display: flex;
  background: none;
  justify-content: center;
  align-items: center;
  color: gray;
  border: none;
  font-size: 2rem;

  &:focus {
    background: none;
  }
}

.relative {
  position: relative;
  overflow: visible
}

.client-data {
  border-radius: 4px;
  background: white;
  width: 700px;
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,.03);
}

.client-header {
  background: white;
  height: 50px;
  width: 700px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,.03);
}

.client-row {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  transition: all 100ms;
  position: relative;


  &.hover-effect:hover {
    background: #2980b9;
    box-shadow: 1px 1px 5px 3px rgba(0,0,0,.03);
    color: white
  }
}
  


.fifty-margin {
  margin-top: 60px;
}

