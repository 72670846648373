@import '../variables.scss';

.add-icon {
    background-color: $primary;
    margin-left: .5em;
    color: $offtext;
    width: 45px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 3em;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(85, 115, 233, 0.25);
    p {
        padding: 0;
        margin: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 200;
        font-size: 36px;
    }
}
