@import '../variables.scss';

.back-btn {
    position: absolute;
    top: 1rem;
    left: 1rem;
    cursor: pointer;
    width: 100px;
    background: white;
    text-align: center;
  }
  
.dashboard-back-btn {
    margin-left: 1rem;
    padding: .2em 1em;
    cursor: pointer;
    width: 80px;
background: $primarydark;
    text-align: center;
    color: $offtext;
    margin-right: 1rem;
    box-shadow: 0px 0px 1px rgba(0,0,0,.3);
    transition: background 100ms;
    &:hover {
        background-color: $primary;
    }
}