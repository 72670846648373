@import '../../../../styles/variables.scss';

.project-show {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5.5em;
    .project-blob {
        width: 22em;
        margin-top: 1em;
    }
}

.project-page {
    margin-top: 4.2em;
    padding-bottom: 4rem;
    height: calc(100vh - 60px);
    overflow-y: scroll;
    .progress-bar-bg {
        border-radius: 4px;
        padding: 2px 6px;
        width: 26em;
        height: 2em;
        color: $text;
        background-color: $card;
        font-size: 1.2em;
        font-weight: bolder;
        // .percent-number {

        // }
        .filled-bar {
          height: 100%;
          background-color: $primary;
        }
    }
}

