@import '../styles/common.scss';

.dashboard {
  padding: 3rem 1rem;
  height: 100vh;
  overflow-y: scroll;
  margin-bottom: 2rem;
  .top-section {
    display: flex;  
    justify-content: space-between;
  }
  h2 {
    font-weight: 900;
    span {
      color: #c0392b;
    }
  }

  p {
    span {
      font-weight: bold;
      color: #9b59b6
    }
  }
  .db-cards-right {
    right: 0%;
    position: absolute;
    top: 2%;
  }
  .deadlines-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;

    h2 {
      font-size: 1.2rem;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }

    .deadline-project-box {
      background: white;
      min-height: auto;
      max-height: 500px;
      width: 65%;
      border-radius: 6px;
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.03);
      overflow-y: scroll;
      align-self: self-start;
    }

    .client-header {
      position: unset;
      border-bottom: solid 1px rgba(0,0,0,.03);
      width: calc(100% - 1rem);


      &.shorter {
        width:  100%;
      }
      

      b {
        width: 25%;
      }

      b.content {
        width: calc(24% + 150px);
        padding-right: 11px;
      }
    }

    .activity-project-box {
      background: white;
      height: 500px;
      width: 100%;
      border-radius: 6px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.03);
      overflow-y: scroll;

      .comment {
        padding: .5rem 2rem;
        display: flex;

        &:hover {
          background: #7ab5ff4d;
        }

        p {
          padding: 0;
          margin: 0;
        }

        .comment-photo {
          margin-top: .5rem;
          margin-right: 1rem;
          border: solid 2px black;
          border-radius: 50%;
          height: 50px;
          width: 50px;
        }
    
      }

      .line {
        height: 1px;
        background: #7ab5ff4d;
        width: 100%;
      }
    }

    .deadline-milestone-box {
      margin-top: 15px;
      min-height: auto;
      max-height: 500px;
      width: 33%;
      background: white;
      border-radius: 6px;
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.03);
      overflow-y: scroll;
      align-self: self-start;

      &.payments {
        transform: translateY(-60px);
      }
    }

    .deadline-completed-box {
      margin-top: 1rem;
      min-height: auto;
      max-height: 500px;
      width: 99%;
      background: white;
      border-radius: 6px;
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.03);
      overflow-y: scroll;
      align-self: self-start;
    }


    .deadline-header {
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem;
      box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.03);  
    }

    a {
      color: black;
      font-size: 1rem;
    }

  }
}

.capitalize {
  text-transform:capitalize;
}

.hundred-width {
  width: 100%;
}

.blue-text {
  color: #2980b9 !important;
}

.time-header {
  width: 150px;
  text-align: right;
}

.small-grey {
  font-size: 10px;
  color: #082f87;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 40%;
}

.chart-box {
  padding: 1rem;
  width: 98%;
  height: 275px;
  background: white;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  position: relative;

  .relative-hundred {
    position: absolute;
    top: 2rem;
    left: 0rem;
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  
}

.welcome-tutorial {
  .images {
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  .arrow {
    position: absolute;
    top: -150px;
    right: 4rem;
    width: 45%;
  }
}

.sixty {
  width: 55%;
}


@media (max-width:900px) and (min-width:300px) {

  .deadline-milestone-box {
    min-height: auto;
    max-height: 500px;
    width: 33%;
    background: white;
    border-radius: 6px;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.03);
    overflow-y: scroll;
  
    &.payments {
      transform: translateY(0px);
    }
  }

  .left-bar {
    width: 40px;
    overflow: hidden;

    .profile-photo {
      display: none;
    }

    .top-section {
      display: none;
      height: 40px;
      font-size: .5rem;
    }

    a {
      margin-left: 9px;
    }

    .bottom-section a {
      margin-left: 0px;
      font-size: 8px;
    }
  }

  .sixty {
    width: 100%;
    margin-bottom: 10px;
  }

  .column {
    width: 100%;
  }

  .chart-box {
    margin-bottom: 10px;
  }

  .deadline-project-box, .deadline-milestone-box {
    margin-bottom: 10px;
    width: 100% !important;
  }

  .projects-show {
    padding: 1rem !important;
    padding-top: 3rem !important;
    width: calc(100vw - 40px) !important;

    .client-data, .client-title, h3, .delete-btn {
      display: none;
    }

    .conversation-container {
      .delete-btn {
        display: block;
        top: 3rem !important;
        right: -2rem !important;
      }
  
    }
  }

  .projects {
    width: calc(100vw - 40px) !important;

    .action-btn {
      display: none !important;
    }
  }

  .modal {
    left: -110px !important;
    transform: scale(0.6);
  }

  .milestone-modal {
    left: -110px !important;
  }

}
