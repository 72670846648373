body, html {
  margin: 0;
  background: #F5F7FB;
  height: 100%;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  font-family: 8px;
}

.modal-overlay {
  display: block !important;
  background: rgba(0,0,0,.7) !important;
  
  .modal {
    top: 0;
    left: 250px;
    bottom: 0;
    height: 600px;
    width: 600px;
    background: white;
    display: block;
  }
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.8rem;
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1.4rem;
}

h5 {
  font-size: 1.2rem;
}

h6 {
  font-size: 1rem;
}

.no-padding {
  padding: 0;
  margin: 0;
}

.bold {
  font-weight: bold;
}

.editable {
  padding: 0;
  margin: 0;
}
