@charset "UTF-8";
.projects {
  padding-left: 1.4em;
  padding-right: 1.4em;
  width: calc(100vw - 250px);
  height: 100vh;
  overflow-y: scroll;
  padding-top: 4rem; }
  .projects h2 {
    text-align: left;
    width: 100%; }
  .projects .projects-list {
    padding-top: 1.4em;
    margin-top: 1.4em;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap; }
    .projects .projects-list .project-container {
      position: relative;
      min-height: 18em;
      width: 18.5em;
      margin-left: .8em;
      margin-right: .8em;
      border-radius: .4em;
      background: white;
      margin-bottom: 20px;
      transition: all 250ms;
      box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.03);
      text-decoration: none;
      text-align: center;
      padding: .6em 1.4em .6em 1.4em;
      color: #1a1a1a;
      margin-right: 1rem; }
      .projects .projects-list .project-container:hover {
        transform: translateY(-3px);
        box-shadow: 1px 0px 4px 1px rgba(0, 0, 0, 0.03);
        text-decoration: none; }
      .projects .projects-list .project-container.trial-over {
        border: solid 2px #ce2828; }
        .projects .projects-list .project-container.trial-over.new-proj {
          cursor: pointer;
          border: solid 2px #5573E9; }
      .projects .projects-list .project-container .project-content {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative; }
        .projects .projects-list .project-container .project-content .project-title {
          color: #1a1a1a;
          margin-top: .4em;
          font-weight: 700;
          font-size: 1.2em;
          text-align: left;
          margin-bottom: 0; }
        .projects .projects-list .project-container .project-content .link-container {
          display: flex;
          align-items: center; }
          .projects .projects-list .project-container .project-content .link-container .fa-external-link-alt {
            margin-right: .4em; }
          .projects .projects-list .project-container .project-content .link-container .fa-external-link-alt, .projects .projects-list .project-container .project-content .link-container .project-sitelink {
            color: #888888e3;
            font-weight: 600;
            font-size: .8em;
            margin: 6px 0 0 0; }
        .projects .projects-list .project-container .project-content .project-bio {
          color: #888888e3;
          text-align: left; }
        .projects .projects-list .project-container .project-content .comment-length-number {
          color: #888888e3; }
        .projects .projects-list .project-container .project-content .percent-value-container {
          width: 100%;
          margin: 0 auto; }
          .projects .projects-list .project-container .project-content .percent-value-container .percent-value {
            margin: 0em 0em 4px 1em;
            color: gray;
            font-size: 12px;
            padding: 0; }
        .projects .projects-list .project-container .project-content .progress-bar-sm {
          width: 100%;
          margin: 0 auto;
          background-color: #E0E4E8;
          color: #E0E4E8;
          height: .4em;
          border-radius: 1em;
          justify-content: flex-end; }
          .projects .projects-list .project-container .project-content .progress-bar-sm .filled-bar-sm {
            height: 100%;
            width: 30%;
            background-color: #5573E9; }
      .projects .projects-list .project-container .bottom-slot {
        width: 100%;
        display: flex;
        margin-top: 1em;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 0; }
        .projects .projects-list .project-container .bottom-slot .days-left {
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: #FFEBEC;
          color: #FF7272;
          font-size: .8em;
          font-weight: 600;
          border-radius: 4px;
          padding: 4px 12px;
          margin-top: 1em; }
          .projects .projects-list .project-container .bottom-slot .days-left .days-amnt {
            padding: 0;
            margin: 0;
            margin-left: .4em; }
        .projects .projects-list .project-container .bottom-slot .client-tooltip {
          background: black;
          color: white; }
          .projects .projects-list .project-container .bottom-slot .client-tooltip:after {
            border-top-color: black; }
        .projects .projects-list .project-container .bottom-slot .comment-number {
          position: absolute;
          right: 0;
          bottom: 3px; }
        .projects .projects-list .project-container .bottom-slot .client-img {
          border-radius: 8px;
          width: 2em;
          margin-left: 3px;
          border-radius: 50%;
          transition: all 150ms; }
          .projects .projects-list .project-container .bottom-slot .client-img:hover {
            transform: translateY(-2px); }
        .projects .projects-list .project-container .bottom-slot .relative {
          position: relative; }
        .projects .projects-list .project-container .bottom-slot .client-tooltip {
          display: block;
          background: white;
          position: absolute;
          color: black;
          top: 0;
          left: 50%;
          padding: 0.8rem 1rem;
          border-radius: 6px;
          font-size: 0.8rem;
          width: 130px;
          font-weight: bold;
          opacity: 1;
          pointer-events: none;
          text-transform: uppercase;
          transform: translate(-50%, -110%);
          transition: all 0.3s ease;
          z-index: 1;
          box-shadow: 1px 0px 4px 1px rgba(0, 0, 0, 0.06); }
  .projects .action-btn {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background: #2980b9;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    cursor: pointer;
    transition: all 250ms; }
    .projects .action-btn:hover {
      background: #3498db; }

.create-btn {
  position: absolute;
  bottom: 1rem; }

.project-modal {
  padding: 1rem;
  max-height: 100%; }
  .project-modal .modal-top-blob {
    width: 11em;
    margin-top: -.8em; }
  .project-modal .project-new-title, .project-modal .major-input {
    text-align: center;
    margin-top: auto;
    font-family: Roboto;
    font-weight: bolder;
    letter-spacing: 0px; }
  .project-modal .major-input {
    letter-spacing: 1px;
    font-size: 3em;
    text-align: center;
    padding-bottom: .2em; }
  .project-modal .btn-project {
    margin: 0 auto; }
  .project-modal h2, .project-modal h3 {
    text-align: center; }
  .project-modal .delete-options {
    max-width: 50%; }

.projects-show {
  padding: 2rem;
  height: calc(100vh - 3rem);
  overflow-y: scroll;
  margin-bottom: 1rem; }
  .projects-show h1 {
    text-align: left;
    width: 100%; }
  .projects-show .client-title {
    width: 700px;
    position: relative; }

.client-adder {
  position: relative; }

.employee-cal-drop-down {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30px;
  background: white;
  padding: .6rem 0rem;
  max-height: 300px;
  overflow-y: scroll;
  z-index: 100;
  border-radius: 4px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3); }
  .employee-cal-drop-down.on-edit {
    top: 30px; }
  .employee-cal-drop-down.add-client {
    top: 50px;
    right: 0; }
  .employee-cal-drop-down p {
    width: 100%;
    padding: .5rem .9rem;
    cursor: pointer;
    transition: all 150ms;
    margin: 0; }
  .employee-cal-drop-down p:hover {
    background: rgba(0, 0, 0, 0.06);
    color: black; }

.select-employee-form {
  height: 30px;
  width: 100%;
  border: 1px solid #c2c2c2;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 30px; }
  .select-employee-form:after {
    position: absolute;
    right: 2rem;
    font-weight: bold;
    content: '⌄'; }

.conversation-container {
  height: auto;
  width: 98%;
  background: white;
  border-radius: 6px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.03);
  padding: 1rem 2rem; }
  .conversation-container .comment {
    position: relative;
    border-bottom: solid 1px rgba(0, 0, 0, 0.3);
    white-space: pre-line;
    display: flex; }
    .conversation-container .comment .comment-photo {
      margin-top: 1rem;
      margin-right: 1rem;
      border: solid 2px black;
      border-radius: 50%;
      height: 50px;
      width: 50px; }
    .conversation-container .comment .delete-btn {
      position: absolute;
      top: 1rem;
      right: 1rem;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: #c0392b;
      color: white; }
    .conversation-container .comment .edit-btn {
      color: #2980b9;
      background: none;
      border: none;
      width: auto;
      margin: 0;
      margin-left: 5px;
      padding: 0;
      text-align: left; }
    .conversation-container .comment .comment-editor {
      margin-bottom: 5px; }
  .conversation-container .cancel-btn {
    background: none;
    border: solid 1px #e74c3c;
    margin-right: 0;
    float: right;
    color: #e74c3c; }
  .conversation-container.on-milestone .delete-btn {
    top: 1rem;
    right: -1.8rem;
    height: 25px;
    font-size: 10px;
    width: 25px;
    border-radius: 50%;
    background: #c0392b;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center; }
  .conversation-container .like-section {
    position: relative;
    height: 30px;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 5px; }
    .conversation-container .like-section button {
      background: none;
      margin-right: 10px; }
    .conversation-container .like-section .comment-likes {
      display: inline-block; }

.write-comment {
  border-top: solid 1px rgba(0, 0, 0, 0.3);
  padding: 1rem 0rem;
  margin: 1rem 0rem;
  overflow: hidden; }
  .write-comment.edit-mode {
    margin: 0;
    padding: 0;
    border: none;
    margin-bottom: 5px; }
  .write-comment textarea {
    height: 100px;
    padding: 1rem;
    border: solid 1px rgba(0, 0, 0, 0.3); }

.date {
  padding-left: .5rem;
  color: #7f8c8d; }

[type="checkbox"]:not(:checked), [type="checkbox"]:checked {
  opacity: 1;
  pointer-events: all; }

.buttons-container {
  margin-top: 20px; }
  .buttons-container button {
    margin-right: 10px; }

.padding {
  padding: 1rem; }

.milestone-modal {
  max-height: none !important;
  width: 639px !important;
  height: 556px !important;
  border-radius: 10px;
  background: #F1F1F1 !important;
  padding: 0;
  display: flex !important; }
  .milestone-modal .left-side {
    display: block;
    width: 359px;
    height: 556px;
    overflow-y: scroll;
    background: white;
    padding: 23px 23px; }
    .milestone-modal .left-side .title {
      font-family: lato;
      font-size: 24px;
      color: 191919;
      font-weight: bold; }
    .milestone-modal .left-side .description {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      color: #999999;
      margin-top: 10px; }
    .milestone-modal .left-side .sub-title {
      margin-top: 18px;
      font-family: Lato;
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      color: #A3A3A3; }
    .milestone-modal .left-side .due-date {
      width: 117px;
      height: 19px;
      background: #E9E9E9;
      border-radius: 6px;
      text-align: center;
      color: #919191;
      font-size: 12px;
      font-weight: bold; }
    .milestone-modal .left-side .line {
      margin-top: 24px;
      width: 310px;
      height: 3px;
      background: #F7F7F7; }
    .milestone-modal .left-side .small-btn {
      width: 60px; }
  .milestone-modal .right-side {
    width: 280px;
    height: 556px;
    padding-left: 8px;
    padding-top: 20px;
    overflow-y: scroll; }
    .milestone-modal .right-side .due-date {
      width: 90px;
      height: 19px;
      background: #E2E2E2;
      border-radius: 6px;
      text-align: center;
      font-weight: normal;
      color: #919191;
      font-size: 12px; }
    .milestone-modal .right-side .line {
      width: 250px;
      height: 2px;
      margin-top: 25px;
      background: #E9E9E9; }
    .milestone-modal .right-side .sub-title {
      margin-top: 12px;
      font-family: Lato;
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      color: #A3A3A3;
      margin-left: 7px;
      margin-bottom: 12px; }
    .milestone-modal .right-side .action-btn {
      font-family: lato;
      width: 250px;
      height: 30px;
      background: #FFFFFF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      color: #A0A0A0;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 6px;
      transition: all 200ms; }
      .milestone-modal .right-side .action-btn:hover {
        transform: translateY(-1px); }
      .milestone-modal .right-side .action-btn.blue {
        font-family: lato;
        background: #2C33EF;
        color: white;
        display: flex;
        justify-content: space-between;
        text-align: left;
        padding-left: 8px;
        padding-right: 8px;
        font-weight: normal; }
      .milestone-modal .right-side .action-btn.red {
        background: #FC3A3E;
        font-family: lato;
        color: white;
        display: flex;
        justify-content: space-between;
        text-align: left;
        padding-left: 8px;
        padding-right: 8px;
        font-weight: normal; }
      .milestone-modal .right-side .action-btn span {
        cursor: pointer;
        color: white; }
      .milestone-modal .right-side .action-btn a {
        color: white; }

.add-link-form {
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 250px;
  position: relative; }
  .add-link-form input {
    width: 235px;
    background: white;
    padding-left: 1rem; }
  .add-link-form .select-employee-form {
    background: white;
    margin-bottom: 30px; }
  .add-link-form .employee-cal-drop-down {
    top: auto;
    bottom: -28px;
    width: 250px;
    margin-bottom: 30px;
    height: auto; }
  .add-link-form .space-between {
    margin-top: 10px;
    display: flex;
    justify-content: space-between; }
  .add-link-form button {
    width: 100px;
    background: #2980b9;
    border: none;
    color: white; }
