@import './variables.scss';

.timeline-card {
  width: 225px;
  height: 212px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 4px;
}

.past-card {
  background-color: $primary;
  color: $offtext;
  text-align: left;
}

.current {
  background-color: $card;
  color: $text;
  box-shadow: 0px 7px 9px rgba(85, 115, 233, 0.69);
  border: 4px solid $primary;
  border-radius: 4px;
  align-items: center;
}

.timeline {
    padding-top: 1rem;
    overflow: scroll;
    display: flex;
    height: auto;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
    .timeline-card {
      width: 225px;
      height: 212px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 20px;
      cursor: pointer;
      box-sizing: border-box;
      border-radius: 4px;
    }

    .past-card {
      background-color: $primary;
      color: $offtext;
      text-align: left;
    }

    .future-card {
      background-color: $card;
      color: $text;
      text-align: right;
      padding: 0.4em .22em;
      box-shadow: 0px 4px 4px rgba(85, 115, 233, 0.25);
    }

    .current {
      background-color: $card;
      color: $text;
      box-shadow: 0px 7px 9px rgba(85, 115, 233, 0.69);
      border: 4px solid $primary;
      border-radius: 4px;
      align-items: center;
    }
  
    .line {
      height: 5px;
      width: 33px;
      margin: 0 .5rem;
      background: rgba(46, 204, 113,.6);
    }
  
    .timeline-container {
      display: inline-block;
      height: 150px;
      width: 170px;
      border-radius: 6px;
      background: white;
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.03);
      transition: all 250ms;
      text-decoration: none;
      text-align: center;
      padding: 1rem;
      color: black;
      flex-shrink: 0;
  
      &.complete {
        border: solid 6px rgba(46, 204, 113, 1);
      }
  
      &:hover {
        transform: translateY(-3px);
        box-shadow: 1px 0px 4px 1px rgba(0, 0, 0, 0.03);
        text-decoration: none;
      }
  
      p {
        padding-top: 0;
        margin-top: 0;
      }
     
  
    }
    .add-new-milestone {
      border: solid 4px rgba(46, 204, 113,.6);
      // color: white;
    }
  }