@import  '../variables.scss';

.settings-page  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 4em;
    margin-left: 4em;
    padding-bottom: 4rem;
    height: 92vh;
    width: 90vw;
    overflow-y: scroll;
    .section {
        width: 70%;
        padding: 1.6em 2.8em;
        border-radius: 8px;
        background-color: $card;
    }

    .add-clients-con {
        position: relative;

        .employee-cal-drop-down.add-client{
            min-width: 230px;
            top: 34px;
            left: 5px;
        }    
    }

    .add-clients {
        cursor: pointer;
        color: $primary;
        margin-left: .4em;
        font-size: 1.2em;
    }
}
