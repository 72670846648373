
@import '../../styles/variables.scss';

.projects {
  padding-left: 1.4em;
  padding-right: 1.4em;
  width: calc(100vw - 250px);
  height: 100vh;
  overflow-y: scroll;
  padding-top: 4rem;

  h2 {
    text-align: left;
    width: 100%;
  }

  .projects-list {
    // background-color: white;
    // padding-left: 1.4em;
    padding-top: 1.4em;
    margin-top: 1.4em;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .project-container {
      position: relative;
      min-height: 18em;
      width: 18.5em;
      margin-left: .8em;
      margin-right: .8em;
      border-radius: .4em;
      background: white;
      margin-bottom: 20px;
      transition: all 250ms;
      box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.03);
      text-decoration: none;
      text-align: center;
      padding: .6em 1.4em .6em 1.4em;
      color: $text;
      margin-right: 1rem;
      &:hover {
        transform: translateY(-3px);
        box-shadow: 1px 0px 4px 1px rgba(0, 0, 0, 0.03);
        text-decoration: none;
      }

      &.trial-over {
        border: solid 2px $error;
        &.new-proj {
          cursor: pointer;
          border: solid 2px $primary;
        }
      }
      .project-content {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;  
        .project-title {
          color: $text;
          margin-top: .4em;
          font-weight: 700;
          font-size: 1.2em;
          text-align: left;
          margin-bottom: 0;
        }
        .link-container {
          display: flex;
          align-items: center;
          .fa-external-link-alt {
            margin-right: .4em;
          }
          .fa-external-link-alt, .project-sitelink {
            color: #888888e3;
            font-weight: 600;
            font-size: .8em;
            margin: 6px 0 0 0;
          }
        }
        .project-bio {
          color: #888888e3;
          text-align: left;
        }

        .comment-length-number {
          color: #888888e3;
        }

        .percent-value-container {
          width: 100%;
          margin: 0 auto;
          .percent-value {
            margin: 0em 0em 4px 1em;
            color: gray;
            font-size: 12px;
            padding: 0;
          }
        } 
        .progress-bar-sm {
          width: 100%;
          margin: 0 auto;
          // position: absolute;
          // bottom: 1em;
          background-color: #E0E4E8;
          color: #E0E4E8;
          height: .4em;
          border-radius: 1em;
          justify-content: flex-end;
          .filled-bar-sm {
            height: 100%;
            width: 30%;
            background-color: $primary;
          }
        }
      }
      .bottom-slot {
        width: 100%;
        display: flex;
        margin-top: 1em;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 0;
        .days-left {
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: #FFEBEC;
          color: #FF7272;
          font-size: .8em;
          font-weight: 600;
          // height: 18%;
          border-radius: 4px;
          padding: 4px 12px;
          margin-top: 1em;
          .days-amnt {
            padding: 0;
            margin: 0;
            margin-left: .4em;
          }
        }

        .client-tooltip {
          background: black;
          color: white;
      
          &:after {
            border-top-color: black;
          }
        }


      .comment-number {
        position: absolute;
        right: 0;
        bottom: 3px;
      }

        .client-img {
          border-radius: 8px;
          width: 2em;
          margin-left: 3px;
          border-radius: 50%;
          transition: all 150ms;

          &:hover {
            transform: translateY(-2px);

          }
        }

        .relative {
          position: relative;
        }

        .client-tooltip {
          display: block;
          background: white;
          position: absolute;
          color: black;
          top: 0;
          left: 50%;
          padding: 0.8rem 1rem;
          border-radius: 6px;
          font-size: 0.8rem;
          width: 130px;
          font-weight: bold;
          opacity: 1;
          pointer-events: none;
          text-transform: uppercase;
          transform: translate(-50%, -110%);
          transition: all 0.3s ease;
          z-index: 1;
          box-shadow: 1px 0px 4px 1px rgba(0, 0, 0, 0.06);
        }

      }
    }
  }

  .action-btn {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background: #2980b9;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    cursor: pointer;
    transition: all 250ms;

    &:hover {
      background: #3498db;
    }

  }
}

.create-btn {
  position: absolute;
  bottom: 1rem;
}

.project-modal {
  padding: 1rem;
  max-height: 100%;
  .modal-top-blob {
    width: 11em;
    margin-top: -.8em;
  }
  .project-new-title, .major-input {
    text-align: center;
    margin-top: auto;
    font-family: Roboto;
    font-weight: bolder;
    letter-spacing: 0px;
  }
  .major-input {
    letter-spacing: 1px;
    font-size: 3em;
    text-align: center;
    padding-bottom: .2em;
  }
  .btn-project {
    margin: 0 auto;
  }
  h2, h3 {
    text-align: center;
  }
  .delete-options {
    max-width: 50%;
  }
}

.projects-show {
  padding: 2rem;
  height: calc(100vh - 3rem);
  overflow-y: scroll;
  margin-bottom: 1rem;

  h1 {
    text-align: left;
    width: 100%;
  }

  .client-title {
    width: 700px;
    position: relative;
  }
}

.client-adder {
  position: relative;
}

.employee-cal-drop-down {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30px;
  background: white;
  padding: .6rem 0rem;
  max-height: 300px;
  overflow-y: scroll;
  z-index: 100;
  border-radius: 4px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);

    &.on-edit {
      top: 30px;
    }

    &.add-client {
      top: 50px;
      right: 0;
    }


  p {
    width: 100%;
    padding: .5rem .9rem;
    cursor: pointer;
    transition: all 150ms;
    margin: 0;
  }

  p:hover {
    background: rgba(0,0,0,.06);
    color: black;
  }
}

.select-employee-form {
  height: 30px;
  width: 100%;
  border: 1px solid #c2c2c2;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 30px;


  &:after {
    position: absolute;
    right: 2rem;
    font-weight: bold;
    content: '⌄';
  }
}

.conversation-container {
  height: auto;
  width: 98%;
  background: white;
  border-radius: 6px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.03);
  padding: 1rem 2rem;

  .comment {
    position: relative;
    border-bottom: solid 1px rgba(0,0,0,.3);
    white-space: pre-line;
    display: flex;

    .comment-photo {
      margin-top: 1rem;
      margin-right: 1rem;
      border: solid 2px black;
      border-radius: 50%;
      height: 50px;
      width: 50px;
    }

    .delete-btn {
      position: absolute;
      top: 1rem;
      right: 1rem;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: #c0392b;
      color: white;
    }

    .edit-btn {
      color: #2980b9;
      background: none;
      border: none;
      width: auto;
      margin: 0;
      margin-left: 5px;
      padding: 0;
      text-align: left;
    }

    .comment-editor {
      margin-bottom: 5px;
    }
  }

  .cancel-btn {
    background: none;
    border: solid 1px #e74c3c;
    margin-right: 0;
    float: right;
    color: #e74c3c;
  }

  &.on-milestone {

    .delete-btn {
      top: 1rem;
      right: -1.8rem;
      height: 25px;
      font-size: 10px;
      width: 25px;
      border-radius: 50%;
      background: #c0392b;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .like-section {
    position: relative;
    height: 30px;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 5px;

    button {
      background: none;
      margin-right: 10px;
    }

    .comment-likes {
      display: inline-block;
    }

  }

}

.write-comment {
  border-top: solid 1px rgba(0,0,0,.3);
  padding: 1rem 0rem;
  margin: 1rem 0rem;
  overflow: hidden;

  &.edit-mode {
    margin: 0;
    padding: 0;
    border: none;
    margin-bottom: 5px;
  }

  textarea {
    height: 100px;
    padding: 1rem;
    border: solid 1px rgba(0,0,0,.3);
  }


}
.date {
  padding-left: .5rem;
  color: #7f8c8d;
}



[type="checkbox"]:not(:checked), [type="checkbox"]:checked {
  opacity: 1;
  pointer-events: all;
}

.buttons-container {
  margin-top: 20px;

  button {
    margin-right: 10px;
  }
}

.padding {
  padding: 1rem;
}


.milestone-modal {
  max-height: none !important;
  width: 639px !important;
  height: 556px !important;
  border-radius: 10px;
  background: #F1F1F1 !important;
  padding: 0;
  display: flex !important;

  .left-side {
    display: block;
    width: 359px;
    height: 556px;
    overflow-y: scroll;
    background: white;
    padding: 23px 23px;

    .title {
      font-family: lato;
      font-size: 24px;
      color: 191919;
      font-weight: bold;
    }

    .description {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      color: #999999;
      margin-top: 10px;
    }

    .sub-title {
      margin-top: 18px;
      font-family: Lato;
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      color: #A3A3A3;
    }

    .due-date {
      width: 117px;
      height: 19px;
      background: #E9E9E9;
      border-radius: 6px;
      text-align: center;
      color: #919191;
      font-size: 12px;
      font-weight: bold;
    }

    .line {
      margin-top: 24px;
      width: 310px;
      height: 3px;
      background: #F7F7F7;

    }

    .small-btn {
      width: 60px;
    }

  }

  .right-side {
    width: 280px;
    height: 556px;
    padding-left: 8px;
    padding-top: 20px;
    overflow-y: scroll;

    .due-date {
      width: 90px;
      height: 19px;
      background: #E2E2E2;
      border-radius: 6px;
      text-align: center;
      font-weight: normal;
      color: #919191;
      font-size: 12px;
    }

    .line {
      width: 250px;
      height: 2px;
      margin-top: 25px;
      background: #E9E9E9;
    }

    .sub-title {
      margin-top: 12px;
      font-family: Lato;
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      color: #A3A3A3;
      margin-left: 7px;
      margin-bottom: 12px;
    }

    .action-btn {
      font-family: lato;
      width: 250px;
      height: 30px;
      background: #FFFFFF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      color: #A0A0A0;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 6px;
      transition: all 200ms;

      &:hover {
        transform: translateY(-1px);
      }

      &.blue {
        font-family: lato;
        background: #2C33EF;
        color: white;
        display: flex;
        justify-content: space-between;
        text-align: left;
        padding-left: 8px;
        padding-right: 8px;
        font-weight: normal;
      }

      &.red {
        background: #FC3A3E;
        font-family: lato;
        color: white;
        display: flex;
        justify-content: space-between;
        text-align: left;
        padding-left: 8px;
        padding-right: 8px;
        font-weight: normal;
      }

      span {
        cursor: pointer;
        color: white;
      }

      a {
        color: white;
      }
  
    }




  }
}


.add-link-form {
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 250px;
  position: relative;

  input {
    width: 235px;
    background: white;
    padding-left: 1rem;
  }

  .select-employee-form {
    background: white;
    margin-bottom: 30px;
  }

  .employee-cal-drop-down {
    top: auto;
    bottom: -28px;
    width: 250px;
    margin-bottom: 30px;
    height:auto;
  }

  .space-between {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  button {
    width: 100px;
    background: #2980b9;
    border: none;
    color: white;
  }
}
