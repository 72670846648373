.settings-containers {
  margin-top: 50px;
  width: 100%;
  height: calc(100vh - 50px);
  padding-bottom: 50px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  h1 {
    padding-top: 15px;
    color: #383838;
  }

  .settings-box {
    border-radius: 5px;
    background: white;
    height: 362px;
    width: 812px;
  }

  .half-and-half {
    display: flex;
    justify-content: space-between;
    padding: 40px;

    div {
      width: calc(50% - 30px);

    }
  }

  button { 
    margin-top: 10px;
    background: #5573E9;
    border-radius: 4px;        
    color: white;
    font-weight: bold;
    height: 40px;
    width: 100%;
    float: right;
  }


  .center-settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 15px;
    padding-bottom: 30px;


    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  

      h2 {
        color: #000000;
        font-size: 17px;
        font-weight: bold;;
      }
  

      input {
        left: 3px;
        top: 19px;
        position: relative;
        height: 14px;
        opacity: 0;
        z-index: 100;  
      }
  
      label {
        color: #5B5757;
        cursor: default;
      }

      .small {
        transform: scale(.7) translateX(-70px) translateY(30px);
      }

      button {
        margin-top: 30px;
      }
  
    }

  }

  .updated {
    margin: 5px;
    color: #27ae60;
    font-weight: bold;
    cursor: pointer;
    padding: 5px 15px;
    border: solid 2px #27ae60;
    text-align: center;
  }
}